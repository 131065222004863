<template>
  <div>
    <v-navigation-drawer :clipped="$vuetify.breakpoint.smAndUp"
                         v-model="profilePanel"
                         temporary
                         fixed
                         right
                         floating
                         width="300"
                         color="blue-grey darken-4">
      <ess-loader v-if="$wait.is('profilesLoading')" />

      <template v-else>
        <v-list v-if="mode === 'List' && !$wait.is('profilesLoading')"
                two-line>
          <v-list-item @click.stop="createNewProfile">
            <v-list-item-content>
              <v-list-item-title class="title">
                Create New
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="success">
                mdi-plus
              </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
          <v-list-item v-for="profileItem in profiles"
                       :key="profileItem.id"
                       @click.stop="selectProfile(profileItem)">
            <v-list-item-action>
              <v-icon v-if="profileItem.id === activeProfile.id">
                mdi-checkbox-marked
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ profileItem.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-subtitle-1">
                {{ profileItem.sport }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon color="orange"
                        @click.stop="editProfile(profileItem)">
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <template v-else>
          <v-toolbar color="purple darken-3">
            <v-btn icon
                   @click="cancelEditProfile">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ mode }} Profile</v-toolbar-title>
          </v-toolbar>
          <ValidationObserver ref="obs"
                              v-slot="{invalid, passes}">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row justify="center">
                    <v-icon size="140"
                            color="purple lighten-2">
                      mdi-card-account-details
                    </v-icon>
                  </v-row>
                  <p class="subHeading text--lighten-3 grey--text mb-3">
                    Profiles are used to separate Athletes, RPE and Fitness Goals between different sports and teams.
                  </p>
                  <p class="subHeading text--lighten-3 grey--text mb-3">
                    To create a Profile, choose a name and sport below. The Profile name can be either a sport or team name
                  </p>
                </v-col>
                <v-col cols="12"
                       class="py-0">
                  <ess-text-field v-model="profile.name"
                                  label="Profile Name"
                                  rules="required"
                                  placeholder="Profile Name" />
                </v-col>
                <v-col cols="12"
                       class="py-0">
                  <ess-autocomplete v-model="profile.sport"
                                    :items="sports"
                                    rules="required"
                                    label="Sport"
                                    cache-items
                                    item-text="name"
                                    item-value="name" />
                </v-col>
                <v-col cols="12">
                  <v-btn v-if="mode === 'Update'"
                         icon>
                    <v-icon color="error"
                            @click.native="dialogAction.isActive = true">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                  <v-btn v-if="mode === 'Create'"
                         text
                         @click="cancelEditProfile">
                    Cancel
                  </v-btn>
                  <v-btn :loading="$wait.is('profileLoading')"
                         :disabled="invalid"
                         color="success"
                         class="float-right"
                         @click="passes(saveProfile)">
                    {{ mode }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </template>
      </template>
    </v-navigation-drawer>
    <ess-dialog-notification v-if="dialogAction.isActive"
                             :title="dialogAction.title"
                             :content="dialogAction.content"
                             :alert="dialogAction.alert"
                             :button="dialogAction.button"
                             @cancelevent="dialogAction.isActive = false"
                             @action="deleteSelectedProfile" />

    <ess-dialog-plain v-if="plainDialog.visible"
                      :title="plainDialog.title"
                      :color="plainDialog.color"
                      :message="plainDialog.message"
                      :button-text="plainDialog.buttonText"
                      @action="plainDialogAction()" />
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _isNil from 'lodash/isNil';
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationObserver } from 'vee-validate';

import EssDialogNotification from '@/components/core/EssDialogNotification.vue';
import EssDialogPlain from '@/components/core/EssDialogPlain.vue';

import { PROFILE_STATUS } from '@/config/constants';
import { SPORTS_LIST } from '@/config/defaults';
import Profile from '@/models/Profile';

export default {
  components: {
    ValidationObserver,
    EssDialogNotification,
    EssDialogPlain,
  },
  props: {},
  data() {
    return {
      version: process.env.VUE_APP_VERSION || '0.0.0',
      mode: 'List',
      profile: new Profile(),
      sports: SPORTS_LIST,
      profileStatus: null,
      plainDialog: {
        title: '',
        color: 'grey lighten-2',
        message: '',
        buttonText: 'Ok',
        visible: false,
      },
      dialogAction: {
        currentAction: '',
        isActive: false,
        content: 'Are you sure you want to delete this profile?',
        title: 'Delete Profile',
        button: {
          ok: {
            text: 'Yes',
            class: ['error'],
          },
          cancel: {
            text: 'No',
          },
        },
        alert: {
          show: true,
          content: `This action cannot be reversed! When deleting a Profile, 
            you will also lose all data attached to this Profile. This includes but are not limited to 
            Athletes, Groups, RPE Sessions and Reporting, Fitness and Gym Goals, Messaging and Custom Workouts`,
          type: 'warning',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      profiles: 'profiles/profiles',
      activeProfile: 'profiles/activeProfile',
    }),

    profilePanel: {
      get() {
        return this.$store.state.session.profilePanel;
      },
      set(value) {
        this.$store.commit('session/profilePanel', value);
      },
    },
  },

  watch: {
    profilePanel(value) {
      if (!value) {
        this.mode = 'List';
        this.checkActiveProfile();
      }
    },
  },

  async created() {
    this.loadData();
  },

  methods: {
    ...mapActions('profiles', ['loadCoachProfiles', 'selectActiveProfile', 'createProfile', 'updateProfile', 'deleteProfile']),

    ...mapWaitingActions('profiles', {
      loadCoachProfiles: 'profilesLoading',
      selectActiveProfile: 'profileLoading',
      createProfile: 'profileLoading',
      createUserProfile: 'profileLoading',
      updateProfile: 'profileLoading',
    }),

    async loadData() {
      await this.loadCoachProfiles(this.authUser.id);

      // TEMP STUFF
      this.checkActiveProfile();
    },

    createNewProfile() {
      this.mode = 'Create';
      this.clearProfileForm();
    },

    checkActiveProfile() {
      if (this.$wait.is('profileLoading')) {
        return;
      }

      this.profileStatus = null;
      if (this.profiles.length === 0) {
        this.profileStatus = PROFILE_STATUS.MISSING;
      } else if (_isNil(this.activeProfile.id)) {
        this.profileStatus = PROFILE_STATUS.ACTIVE;
      }

      switch (this.profileStatus) {
        case PROFILE_STATUS.ACTIVE:
          this.plainDialog.title = 'No Profile Selected';
          this.plainDialog.color = 'warning';
          this.plainDialog.message = 'You do not seem to have an active Profile selected. Please select one now.';
          this.plainDialog.buttonText = 'Select';
          this.plainDialog.visible = true;
          break;
        case PROFILE_STATUS.MISSING:
          this.plainDialog.title = 'Missing Profile';
          this.plainDialog.color = 'error';
          this.plainDialog.message = 'You currently have no Profiles. Please create one now';
          this.plainDialog.buttonText = 'Create';
          this.plainDialog.visible = true;
          break;
        default:
      }
    },

    clearProfileForm() {
      this.profile = new Profile();
      this.profile.sport = this.sports[0].name;
    },

    async saveProfile() {
      const payload = {
        coachId: this.authUser.id,
        profile: _cloneDeep(this.profile),
      };
      switch (this.mode) {
        case 'Create':
          await this.createProfile(payload);
          this.mode = 'List';
          this.profilePanel = false;
          break;
        case 'Update':
          await this.updateProfile(payload);
          break;
        default:
      }

      this.clearProfileForm();
      this.mode = 'List';
    },

    async selectProfile(profile) {
      this.profilePanel = false;

      await this.selectActiveProfile({
        coachId: this.authUser.id,
        profile,
      });

      this.profileStatus = null;
    },

    editProfile(profile) {
      this.profile = _cloneDeep(profile);
      this.mode = 'Update';
    },

    cancelEditProfile() {
      this.mode = 'List';
      this.clearProfileForm();
    },

    async deleteSelectedProfile() {
      const payload = {
        coachId: this.authUser.id,
        profileId: this.profile.id,
      };

      await this.deleteProfile(payload);

      this.dialogAction.isActive = false;
      this.mode = 'List';
      this.clearProfileForm();
    },

    plainDialogAction() {
      switch (this.profileStatus) {
        case 'ACTIVE':
          this.$store.commit('session/profilePanel', true);
          break;
        case 'MISSING':
          this.mode = 'Create';
          this.$store.commit('session/profilePanel', true);
          break;
        default:
      }
      this.plainDialog.visible = false;
    },
  },
};
</script>
