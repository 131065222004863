<template>
  <v-dialog v-model="visible"
            persistent
            width="500">
    <v-card>
      <v-card-title v-if="title"
                    :class="color"
                    class="text-h5"
                    primary-title>
        {{ title }}
      </v-card-title>

      <v-card-text class="text-subtitle-2 mt-4">
        {{ message }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn :color="color"
               @click.native="action()">
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'grey lighten-2',
    },
    message: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Close',
    },
  },

  data() {
    return {
      visible: true,
    };
  },

  methods: {
    action() {
      this.$emit('action');
    },
  },
};
</script>
